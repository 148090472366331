

import React, { useState } from 'react';
import { useEffect } from 'react';
import '../../react-calendar/src/styles.scss'; // main style file
import '../../react-calendar/src/theme/default.scss'; // theme css file
import {Dropdown, OverlayTrigger, Popover} from "react-bootstrap";

const OfficeSelector = (props) => {
    const [office, setOffice] = useState(null);

    let offices = [];
    for(var i in props.offices) {
        offices.push(props.offices[i]);
    }

    if (offices.length === 1) {
        return <div></div>
    }


    useEffect(() => {
        if (props.office) {
            // Find by id
            for(var i in offices) {
                if (offices[i].id == props.office) {
                    setOffice(offices[i]);
                    break;
                }
            }
        }
    }, []);

    const handleOfficeChange = (office) => {
        setOffice(office);
        if (props.onOfficeChange) {
            props.onOfficeChange(office);
        }
    }

    const officeName = office ? office.name : 'Vyberte pobočku';

    if (props.lockOffice) {
        const popoverHoverFocus = (
            <Popover id="popover-trigger-hover-focus" className="p-3" title="Pobočka">
                Pobočka je vybraná automaticky podle vašeho košíku. Pro změnu pobočky prosím první odeberte všechy produkty z košíku.
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={popoverHoverFocus}
            >
                <div className="me-4">
                    <span className="fw-bold">{officeName}</span>
                </div>
            </OverlayTrigger>
        );

    }

    return (
       <div className="me-4">
           {officeName}
       </div>
    );
}

export default OfficeSelector;


import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ItemDatePicker, {AddToCartButton} from "@/modules/front/ItemDatePicker";

const OrderItemPackageButton = (props) => {
    const [show, setShow] = useState(false);
    const [calendarData, setCalendarData] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let addToCartButton = null;
    const handleChange = (data) => {
        if (
            !calendarData
            || data.allowNext !== calendarData.allowNext
            || data.startDate !== calendarData.startDate
            || data.endDate !== calendarData.endDate)
        {
            setCalendarData(data);
        }
    }

    if (calendarData && calendarData.allowNext) {
        addToCartButton = <AddToCartButton locale={props.locale} packageId={props.packageId} startDate={calendarData.startDate} endDate={calendarData.endDate} officeId={props.office} />
    }
    else {
        addToCartButton = <AddToCartButton />
    }

    const lengthDays = props.lengthHours / 24;
    console.log(props);
    return <>
        <a className="btn btn-primary px-3 py-2 ms-2" onClick={handleShow}>KOUPIT</a>


        <Modal show={show} onHide={handleClose} centered={true} size={"lg"} fullscreen="sm-down">
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className="text-black">Výběr termínu</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">
                    <ItemDatePicker locale={props.locale}
                                    seasonId={props.seasonId}
                                    fixedLength={lengthDays}
                                    hideInfo={true}
                                    onChange={handleChange}
                                    offices={props.offices}
                                    office={props.office}
                                    lockOffice={props.lockOffice}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between flex-grow-1 align-items-center">
                    <Button variant="secondary" onClick={handleClose}>
                        Zavřít
                    </Button>
                    {addToCartButton}
                </div>
            </Modal.Footer>
        </Modal>
    </>
}

export default OrderItemPackageButton;